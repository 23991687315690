const ja = {
  translation: {
    // define translations below
    form: {
      email: 'メールの形式で入力して下さい。',
      password: {
        minLength: 'パスワードは8文字以上である必要があります。',
        confirm: '新しいパスワードと新しいパスワード確認用が一致していません。',
        strong:
          'パスワードには、大文字1文字、特殊文字1文字、および少なくとも8文字が含まれている必要があります。',
        incorrect:
          '認証が失敗しました。メールアドレスとパスワードが正しいかどうかを確認してください。',
      },
      invalid_url: '会社URLが無効です。',
      account_type: 'アカウント種別',
      last_name: '姓',
      first_name: '名',
      full_name: '担当者名',
      viewer_full_name: '姓名',
      select_account_type: 'ユーザー',
      select_prefecture: '都道府県を選択',
      select_industry: '業界を選択',
      select_department: '部署を選択',
      select_job_title: '役職を選択',
      select_employee_size: '従業員規模を選択',
      enter_last_name: '姓を入力',
      enter_first_name: '名を入力',
      telephone_number: '電話番号',
      department_title: '部署/役職',
      enter_furigana_last_name: '姓（カナ）を入力',
      enter_furigana_first_name: '名（カナ）を入力',
      enter_telephone_number: '電話番号（※ハイフンを付けてご入力ください）',
      enter_email: 'メールアドレスを入力',
      enter_address: '市区町村以降を入力',
      enter_company_name: '会社名を入力',
      enter_year_establish: '設立年を入力',
      enter_url: '会社URLを入力',
      required: '{{ field }}を入力してください。',
      required_field: '{{ field }}は必須項目です。',
      required_item: '必須項目です。',
      required_label: '印は必須項目です',
      accept_term_required: 'は必須項目です。',
      account_type_required: 'アカウント種別は必須項目です。',
      last_name_required: '姓は必須項目です。',
      first_name_required: '名は必須項目です。',
      furigana_first_name_required: 'メイは必須項目です。',
      furigana_last_name_required: 'セイは必須項目です。',
      telephone_number_required: '電話番号は必須項目です。',
      email_required: 'メールアドレスは必須項目です。',
      company_name_required: '会社名は必須項目です。',
      prefecture_required: '都道府県は必須項目です。',
      city_required: '市区町村以降は必須項目です。',
      industry_required: '業種は必須項目です。',
      department_required: '部署は必須項目です。',
      title_required: '役職は必須項目です。',
      number_of_employee_required: '従業員規模は必須項目です。',
      year_establish_required: '設立年は必須項目です。',
      company_url_required: '会社URLは必須項目です。',
      company_address: '所在地',
      format: '{{ field }}の形式で入力して下さい。',
      katakana: '全角カナ名で入力してください。',
      number: '整数をご入力ください。',
      enter_new_email_address: '新しいメールアドレスを入力',
      reenter_new_email_address: '新しいメールアドレスを再入力',
      email_address_do_not_match: '現在と新しいメールアドレスが一致しません。',
      first_name_max: '名の最大入力文字数は255文字です。',
      last_name_max: '姓の最大入力文字数は255文字です。',
      email_max: 'メールアドレスの最大入力文字数は255文字です。',
      furigana_first_name_max: 'メイの最大入力文字数は255文字です。',
      furigana_last_name_max: 'セイの最大入力文字数は255文字です。',
      telephone_max: '電話番号の最大入力文字数は10文字です。',
      company_name_max: '会社名の最大入力文字数は255文字です。',
      address_max: '所在地の最大入力文字数は255文字です。',
      company_url_max: '会社URLの最大入力文字数は255文字です。',
      year_establish_max: '設立年の最大入力文字数は255文字です。',
      invalid_amount: '正しい金額を入力してください',
      image_not_allowed: 'ニュース記事のコンテンツでは画像はサポートされていません。',
    },
    modal: {
      logout_title: 'ログアウト',
      logout_subtitle: 'ログアウトしますか?',
      logout_button: 'ログアウト',
      cancel: 'キャンセル',
      ok: 'OK',
      contact_admin_title: 'SaaSMart運営へのご連絡',
      contact_admin_subtitle:
        '掲載企業として登録・掲載をご希望の方は、SaaSMart運営にお問い合わせください',
      forgot_password_title: 'メールを確認してください',
      forgot_password_subtitle: '登録のメールアドレスにパスワードリセット用のリンクを送信しました',
      reset_password_title: 'パスワードがリセットされました',
      reset_password_subtitle: '新しいパスワードで再ログインしてください',
      set_password_title: 'パスワードの登録が完了しました。      ',
      set_password_subtitle: 'ご登録いただいたパスワードでログインしてください。',
      email_address_changed: 'メールアドレスが変更されました',
      email_address_subtitle:
        'ログアウト後、新しいメールアドレスに送信されたリンクをクリックし、変更を完了してください',
      password_change: 'パスワードが変更されました',
      email_confirmation: 'メールアドレス確認',
      email_confirmation_message: '登録されたメールアドレスに本人確認メールが送信されました。',
      email_confirmation_message_sub:
        '※メールが届かない場合は、迷惑メールフォルダーに振り分けられている可能性がありますのでご確認ください。',
      delete_title: 'サービスの削除',
      delete_subtitle: '選択したサービスを削除してよろしいですか？',
      delete: '削除',
      service_reset_title: 'リセット確認',
      service_reset_subtitle: '選択したプランをリセットしてよろしいですか？',
      account_deletion_confirmation: 'アカウント削除確認',
      account_deletion_message: '削除してもよろしいでしょうか？',
    },
    labels: {
      first_name: 'ファーストネーム',
      last_name: '苗字',
      account_type: 'アカウントタイプ',
      furigana_name: 'フリガナ名',
      furigana_first_name: 'メイ',
      furigana_last_name: 'セイ',
      furigana_full_name: '担当者名（フリガナ）',
      viewer_furigana_name: 'セイメイ',
      phone_number: '電話番号 (半角数字)',
      company_name: '会社名',
      prefecture: 'Prefecture',
      address: '会社住所',
      industry: '業界',
      department_job_title: '部署区分/役職区分',
      job_title: '役職区分',
      number_of_employees: '従業員規模',
      year_established: '設立年',
      company_address: '会社住所',
      url: '会社URL',
      login: 'ログイン',
      signup: '登録',
      remember_me: '私を覚えてますか',
      forgot_password: 'パスワードをお忘れですか?',
      email_address: 'メールアドレス',
      password: 'パスワード',
      confirm_password: 'パスワードを認証する',
      submit: '送信',
      update: 'アップデート',
      save: '保存する',
      add_new: 'サービスを追加',
      reset_password: 'パスワードリセット',
      password_setting: 'パスワード設定',
      current_password: '現在のパスワード',
      new_password: '新しいパスワード',
      confirm_new_password: '新しいパスワード（確認）',
      set_confirm_password: 'パスワード（確認）',
      enter_keyword: 'キーワード入力',
      get_started: 'はじめましょう',
      integrations: '統合',
      settings: '設定',
      documentation: 'ドキュメンテーション',
      fullname: 'フルネーム',
      inquiry_content: 'お問合わせ内容',
      navigation: 'ナビゲーション',
      resources: 'リソース',
      cancel: 'キャンセル',
      action: 'アクション',
      image_upload: '画像アップロード',
      delete_image: '画像削除',
      select_account: '登録するアカウント種別の選択',
      register_confirmation_message: '登録するアカウント種別を以下からお選びください',
      register_viewer: 'ユーザーとして登録',
      register_listing_company: '掲載企業として登録',
      viewer_login: 'ユーザーログイン',
      company_login: '掲載企業ログイン',
      administrator_login: '管理者ログイン',
      ok: 'OK',
      no_account: 'アカウントをお持ちですか？',
      register: '新規アカウント登録',
      reset_pass: 'パスワードリセット',
      update_pass: 'パスワードを変更',
      set_pass: 'パスワードを設定する',
      viewer: 'ユーザー',
      listing_company: '掲載企業',
      administrator: '管理者',
      tax_excluded: '(税抜)',
      month: '月',
      months: 'ヶ月',
      upload: 'アップロード',
      management: '管理',
      view: '表示',
      back: '戻る',
    },
    placeholders: {
      email: 'メールアドレス',
      password: 'パスワード',
      forgot_email: '登録のメールアドレスを入力',
      reset_password: '現在のパスワードを入力',
      new_password: '新しいパスワードを入力',
      confirm_reset_password: '新しいパスワードを再入力',
      service_name: 'サービス名',
      service_overview: 'サービス概要',
      category: 'カテゴリ 》サブカテゴリ',
      installation_record: '導入済みの企業を入力してください',
      current_password: '現在のパスワードを入力',
      initial_password: 'パスワードを入力',
      initial_confirm_password: 'パスワードを再入力',
      select_category: 'カテゴリを選択する',
      inquiry_name: '名前を入力',
      inquiry_email: 'メールアドレスを入力',
      inquiry_company: '会社名を入力',
      inquiry_type: 'お問い合わせ内容 を選択',
      inquiry_content: '問い合わせ内容詳細を入力',
    },
    pages: {
      privacy_policy: '個人情報保護方針',
      signup: {
        agree_to_terms: '[サインアップ]をクリックすると、読んだことに同意したことになります',
        signup_complete:
          '確認メールが受信トレイに送信されました。 リンクをクリックして、登録プロセスを完了します。',
        terms_conditions: '規約と条件',
        create_free_account: '無料アカウントを作成する',
        account_registration: 'アカウント登録',
        of_system: '本システムの',
        agree: 'に同意します',
        privacy_policy: ' プライバシーポリシー',
        and: 'および',
        terms_of_services: '利用規約',
        user_page_title: 'ユーザー登録のページ',
        user_page_description: 'SaaSをお探しの企業様はこちらからユーザー登録をお願いします。',
        company_page_title: '掲載企業登録のページ',
        company_page_description: 'サービスの掲載をご希望の企業様はこちらから登録をお願いします。',
      },
      forgot_password: {
        sub_heading: 'パスワードをリセットします',
        sub_heading2: '登録したメールアドレスを入力してください',
        success: 'パスワードをリセットする方法については、受信トレイを確認してください。',
      },
      set_password: {
        sub_heading: '初期パスワードを設定してください。',
      },
      reset_password: {
        sub_heading: '新しいパスワードを入力してください',
        success: 'パスワードは正常に更新されました。',
      },
      users: {
        user_created: 'ユーザーが作成されました。',
        user_updated: 'ユーザーの詳細が更新されました。',
        user_deleted: 'ユーザーが削除されました',
        add_user: 'ユーザーを追加する',
        edit_user: 'ユーザー編集',
        delete_user: 'ユーザーを削除',
        first_name: 'ファーストネーム',
        last_name: '苗字',
        account_name: 'アカウント名',
        company_name: '会社名',
        email_address: '電子メールアドレス',
        status: '状態',
        delete_confirmation: '選択したユーザーを削除してもよろしいですか？',
      },
      activate: {
        heading: 'アカウントをアクティブ化',
        subtitle: 'アカウントをアクティブ化するためのパスワードを設定します。',
        activated: 'アカウントが有効になりました。 これで、アカウントにログインできます。',
      },
      dashboard: {
        main_heading: 'React Base Templateへようこそ！',
        sub_heading: 'Reactプロジェクトの開発に関する軽量の定型文。',
        new_users: '新しいユーザー',
        total_sales: '総売上高',
        total_orders: '総注文数',
      },
      not_found: {
        title: 'ページが見つかりません',
        sub_heading: 'お探しのページは削除されたか、別の場所へ移動した可能性があります。',
        back_to_top: 'トップページへ戻る',
      },
      faq: {
        heading: 'よくあるご質問',
        sub_heading: 'お客様からお問い合わせいただく質問をQ&A形式でまとめました。',
      },
      inquiry: {
        heading: 'お問合せフォーム',
        sub_heading: 'このままお問い合わせされる方は下記のフォームにご入力ください。',
        success_message: 'お問い合わせを送信しました。',
        failed_message: '送信中にエラーが発生しました。',
        instruction: '営業時間：平日（土日・祝日を除く）10:00-18:00',
        note: 'お問合せいただいてから3営業日以内にご返信いたします。',
        name: '名前',
        email_address: 'メールアドレス',
        company_name: '会社名',
        type: 'お問い合わせ内容',
        content: '問い合わせ内容詳細',
        errors: {
          expired: '認証期限が切れました。再度チェックを入れてください。',
        },
        page_title: ' 問い合わせ',
        page_description: '「SaaSMart」に関するご質問やご意見はこちらからお願いします。',
      },
      profile: {
        heading: 'プロファイル編集',
        sub_heading: 'アカウント情報を更新します。',
        success_message: '詳細が正常に更新されました。',
        failed_message: '更新に失敗しました。',
        errors: {
          telephone_format: '数字とダッシュのみ入力してください。',
        },
      },
      landing: {
        main_heading: 'React Base Templateへようこそ！',
        sub_heading: 'Reactプロジェクトの開発に関する軽量の定型文。',
        why_heading: 'なぜベーステンプレートを使用するのですか？',
        docker: {
          heading: '柔軟な環境',
          description:
            '「自分のマシンで動作する」という問題を完全に解消します。 環境のセットアップ、環境固有の問題のデバッグ、およびより移植性が高くセットアップが簡単なコードベースに費やす時間を短縮します。',
        },
        react: {
          heading: '高速で直感的なUI',
          description:
            'ReactJSは非常に直感的に操作でき、UIのレイアウトに双方向性を提供します。 これらのコンポーネントを利用して1つの場所に統合できるように、構成可能です。 したがって、コードははるかに保守可能で柔軟になります。',
        },
        laravel: {
          heading: '強力なAPI',
          description:
            'LaravelのAPI機能を利用してバックエンドAPIを簡単に開発できます。 サードパーティの統合とライブラリが簡単で、すばやく簡単です。',
        },
        our_customers_heading: 'お客様',
        reviews_heading: '私たちのクライアントが言うこと',
        see_all_reviews: 'すべてのレビューを見る',
        call_to_action: '今すぐアプリケーション開発を加速しましょう!',

        // SaasMart
        category: 'カテゴリ',
        all_categories: '全カテゴリ',
        ranking: 'ランキング',
        see_all: '全て見る',
        news_articles: 'ニュース/記事',
        all_articles: '全カテゴリ',
        user_guide: 'ユーザーガイド',
        search: '検索する',
        search_placeholder: 'サービス名、カテゴリ名（例：Salesforce、チャット）で検索する',
        search_placeholder_mobile: 'サービス名、カテゴリ名（例：Salesforce,...',
        page_title: 'SaaS比較サイト',
        page_description:
          'SaaSMart（サースマート）は、数あるSaaSの中から御社に一番あったサービスを見つけ出すためのマッチングプラットフォームです。完全無料で掲載可能です。',
      },
      services: {
        title: 'サービス管理',
        service_details_title: 'サービス詳細',
        service_name: 'サービス名',
        service_overview: 'サービス概要',
        category: 'カテゴリ',
        subcategories: 'サブカテゴリ一覧',
        installation_record: '導入実績',
        service_materials: 'サービス資料',
        uploaded_materials: 'アップ済み資料',
        uploadable_files: 'アップロード可能なファイル：pdf, xlsx, docx, pptx, jpeg, jpg, png',
        upload_note:
          'ここにファイルをドラッグ＆ドロップするか、クリックしてファイルを選択してください',
        success: 'サービスの更新を完了しました',
        service_fee: 'サービス料金',
        service_plan_note:
          '※プランを3つまで登録いただくと、ユーザー様に検討してもらいやすくなります。',
        free_trial_available: '無料トライアルあり',
        free_trial_plan_available: '無料トライアルプランあり',
        free_plan_available: '無料プランあり',
        last_update_date: '最終更新日時',
        message_companies: '掲載企業へメッセージ',
        view_reviews: '口コミ・評判（7）を見る',
        free_plan: '全選択',
        other_plan_type_placeholder: 'その他を入力',
        plan_type_placeholder: 'サブスクリプションのプランタイプを選択',
        service_free_plan: '無料プラン',
        plan_name: 'プラン名',
        plan_summary: 'プラン概要',
        plan_details: 'プラン詳細',
        plan_price: 'プラン価格',
        action: 'アクション',
        category_input: 'カテゴリを入力してください。',
        published: '公開済み',
        private: '非公開',
        publish: '公開する',
        no_data: '表示するデータがありません。',
        search_category: 'カテゴリ検索',
        search_filter: 'フィルター',
        edit_plan_info: 'プラン情報の編集',
        monthly_user: '月額/ユーザー',
        no_rating: '評価なし',
        service_url: 'サービスURL',
        to_be_uploaded_files: 'アップロードするファイルの一覧',
        uploaded_files: 'アップロード済サービス資料',
        low_preview_notice:
          'プレビューの解像度は、ファイルの内容により異なります。ファイルのデータが多い場合、解像度が低い場合がございます。',
        main_display_tooltip: 'メイン資料として設定する',
        no_uploaded: 'サービス資料はまだアップロードされていない。',
        errors: {
          max_file_size: '画像のサイズは、{{ limit }}MB以下でアップロードしてください。',
          invalid_format: '画像は、JPGまたはPNG形式でアップロードしてください。',
          materials: {
            max_file_size: 'ファイルは10MB以下でアップロードしてください。',
            max_files_count: 'アップロード可能なファイル数を超えています。（6ファイルまで）',
            invalid_format:
              'ファイルはPDF、XLSX、DOCX、PPTX、 JPGまたはPNG形式でアップロードしてください。',
            duplicate_file: 'ファイルが重複しています。',
          },
        },
        created_at: '掲載日',
        updated_at: '更新日',
        save_draft: '下書き保存',
        success_draft_message: 'サービスが下書きに保存されました。',
      },
      about: {
        main_heading: '私たちの物語',
        sub_heading:
          '私たちは、私たちが信じる人々のために誇りに思う作品をデザイン、作成、制作するために協力しています。',
        meet_the_team: 'チームに会う',
        team_description:
          '思いやり、独創性、細部へのこだわりは、私たちが設計、製造、販売するすべての製品の基盤です。',
        our_mission: '私たちの使命',
        mission_description:
          '私たちの使命は、ビジネスのトレンドと人々中心の文化と行動を重視する提案を重視する高品質のサービスと製品で、卓越したテクノロジーを広めることです。',
        our_activities: '私たちの活動',
        activities_description: '生計を立てるのに忙しくて、生計を立てることを忘れないでください。',
      },
      account_info: {
        account_info: 'アカウント情報更新',
        renew: '更新',
        success_update: 'アカウント情報の更新を完了しました',
      },
      change_email: {
        change_email: 'メールアドレス変更',
        instruction: '新しいメールアドレスへメールアドレス変更を完了する ためのリンクを送信します',
        new_email: '新しいメールアドレス',
        confirm_new_email: '新しいメールアドレス（確認）',
        change_email_btn: 'メールアドレスを変更',
      },
      change_password: {
        change_password: 'パスワード変更',
        instruction: '現在のパスワードと新しいパスワードを入力して パスワードを変更します',
        notification_1: 'x 半角英数字8文字以上',
        notification_2: 'x 少なくとも1つの記号',
      },
      comparison: {
        service_overview: 'サービス概要',
        service_specifications: 'サービス仕様',
        fees_expenses: '料金・費用',
        conditions: '利用条件',
        service_materials: 'サービス資料',
        reviews: '口コミ・評判',
        others: 'その他',
        service_name: 'サービス名',
        listed_company: '掲載企業',
        evaluation: '総合評価',
        free_trial: '無料トライアルプラン',
        free_plan: '無料プラン',
        plan_price_tax: 'プラン価格(税抜)',
        plan_type: 'プランタイプ',
        initial_cost: '初期費用',
        monthly_user: '月額/ユーザー',
        plan_price: '月額費用',
        minimum_users: '最低利用人数',
        minimum_period: '最低利用期間',
        installation_record: '導入実績',
        missing_documentation: 'サービス資料がありません',
        service_document_request: 'サービス資料請求',
        no_reviews: '口コミ・評判がありません',
        see_more: 'もっと見る',
        no_record: '導入実績がありません',
        thumbnail: 'サムネイル',
      },
      commercial: {
        title: '特定商取引法に基づく表記',
        seller_name: '販売業者の正式名称',
        head_office_location: '本社所在地',
        head_office_phone_number: '本社電話番号',
        email_address: 'メールアドレス',
        operations_manager: '運営統括責任者',
        commodity_price: '商品代金',
        other_charges: '商品代金以外の料金',
        payment_method: '支払方法',
        delivery_timing: '引渡時期（権利の移転時期、役務の提供時期）',
        cancellation_deadline: '有償オプションの解約期限',

        delta: '株式会社デルタ',
        representative: '代表取締役 木山 千春',
        free_note: '掲載企業の方、ユーザーの方、ともに無料でご利用いただけます。',
        paid_note: '掲載企業の方で、有償オプションを申し込まれた場合は料金が発生します。',
        not_applicable: '該当なし',
        notification_date: '設定完了通知日',
      },
      service_material_download: {
        service_material_request: 'サービス資料要求',
        register_as_member: '会員登録して資料請求・ダウンロード',
        confirm_info: 'お客様情報の確認',
        download: '資料数 {{ field }} 件をダウンロード',
        tooltip: 'お客様情報をご入力の上、同意のチェックを入れてください。',
        material_request_list: '請求資料一覧',
        max_download: '最大6ドキュメントのダウンロード。',
        thank_you: 'サービス資料のダウンロードをありがとうございます。',
        sent: '宛にメールにて資料のダウンロードリンクを送信しました。 ',
        note: '※メールが届かない場合は、迷惑メールフォルダーに振り分けられている可能性がございます。',
        back_to_top: 'TOPへ戻る',
        register:
          '会員登録を完了していない方は、会員登録の完了後にサービス資料をご登録のメールアドレスにお送りします。',
        step: '以下のステップに従って会員登録を完了させてください。',
      },
      survey: {
        title: 'サービス資料ダウンロード前のアンケート',
        subtitle1: 'SaaSMartでのサービス資料のダウンロードありがとうございます。',
        subtitle2: 'ダウンロードする前に以下のアンケートをご回答ください。',
        item1: '(1) サービス導入の予定時期を教えてください。',
        item2: '(2) あなたのお立場はどちらに該当しますか？',
        item3: '(3) サービスを選定する際のポイントは何ですか？（※最大２つまで選択可能）',
        item4: '(4) 現在使用しているツールはございますか？',
        item5: '(5) その他（ご要望や共有事項などございましたらご記入ください。）',
      },
      history: {
        title: '資料ダウンロードしたユーザー一覧',
        account_name: 'アカウント名',
        email_address: 'メールアドレス',
        phone: '電話番号',
        requested_date: '投稿日',
        action: 'アクション',
        materials_details: 'アンケート回答・資料ダウンロード詳細',
        account_info: 'アカウント情報',
        questionnaire_content: 'アンケート内容',
        materials: '要求したサービス資料一覧',
        fullname: '氏名',
        furigana_name: '氏名（フリガナ）',
        telephone_number: '電話番号',
        company_name: '会社名',
        location: '所在地',
        city: '市区町村以降を入力',
        department: '部署/役職',
        job_title: '役職を選択',
        url: '会社URL',
        item3: 'サービスを選定する際のポイントは何ですか？',
        item5: 'その他（ご要望や共有事項などございましたらご記入ください。）',
      },
      announcement: {
        title: 'お知らせ一覧',
        announcement_details: 'お知らせ詳細',
        new_announcement: '新規追加',
        form_title: 'お知らせの投稿',
        admin_no_announcements_created: 'お知らせはまだありません。',
        no_announcements_created: 'お知らせはありません。',
        add_new_announcement: '「+ 新規追加」あなたのリストには何もないようだ。',
        label: {
          title: 'お知らせタイトル',
          list_title: 'タイトル',
          recipient: '公開先',
          status: 'ステータス',
          date: '予約日時',
          announcement: 'お知らせ詳細',
          time: '時間',
          publish_date: '投稿日',
        },
        modal: {
          delete_title: 'お知らせの削除',
          delete_subtitle: 'このお知らせを削除してもよろしいですか？',
          delete_message: '選択したお知らせは削除されました。',
          publish_confirm: 'このお知らせを投稿しますか？',
          unpublish_title: '下書きに保存',
          unpublish_subtitle: 'このお知らせを下書きに保存しますか？',
          scheduled_title: '予定を確認する',
          scheduled_subtitle: '選択したスケジュールを本当に確認しますか？',
          discard_title: 'お知らせの編集をキャンセル',
          discard_subtitle: 'お知らせ作成で入力した内容は保存されません。',
        },
        placeholder: {
          title: '通知タイトルをここに記入する',
          announcement: '通知の詳細をここに書く',
        },
      },
      news: {
        title: 'ニュース/記事一覧',
        admin_no_news_created: '作成されたニュース/記事はありません。',
        admin_add_news: '「+新規作成」から作成できます。',
        admin_news_list: 'ニュース記事',
        news_list: '「News」の記事一覧',
        not_available: 'ニュース/記事はまだありません。',
        labels: {
          published_at: '投稿日',
          title: 'タイトル',
          status: 'ステータス',
          draft: '下書きに保存',
          publish: '投稿する',
          content: '内容',
          set_complete: '終了',
          page_name: 'ニュース/記事',
          confirm_complete: '終了',
          add_news: '新規作成',
        },
        placeholder: {
          editor: '内容を入力してください。',
          title: 'お知らせタイトルを入力してください',
        },
        modal: {
          delete_title: 'ニュース/記事を削除',
          delete_subtitle: 'このニュース/記事を削除しますか？',
          delete_message: '選択したニュース/記事は削除されました。',
          complete_title: '終了ステータスに変更',
          complete_subtitle: 'このニュース/記事を終了しますか？',
          complete_message: '選択されたニュース/記事は終了しました。',
          discard_title: 'ニュース/記事の作成をキャンセル',
          discard_subtitle: '入力した内容は保存されません。',
          draft_title: '下書きに保存',
          draft_subtitle: 'このニュース/記事を下書きに保存しますか？',
          draft_message: 'ニュース/記事が下書きに保存されました。',
          publish_title: 'ニュース/記事を投稿',
          publish_subtitle: 'このニュース/記事を投稿しますか？',
          publish_message: '新規のニュース/記事が公開されました。',
        },
      },
      topics: {
        title: 'トピックス一覧',
        admin_no_topics_created: '作成されたトピックスはありません。',
        admin_add_topics: '「+新規作成」から作成できます。',
        exceed_top_display_count:
          'トップページに表示するトピックスを既に3つ選択されているのでこれ以上追加できません。選択し直してください。',
        not_available: 'トピックはまだありません。',
        images_limit: 'アップロード可能なファイル数を超えています。（6ファイルまで）',
        labels: {
          title: 'タイトル',
          content: '内容',
          draft: '下書きに保存',
          publish: '投稿する',
          published_at: '投稿日',
          status: 'ステータス',
          display_top: 'トップページの追加',
          add_topic: '新規作成',
        },
        placeholder: {
          editor: '内容を入力してください。',
          title: 'お知らせタイトルを入力してください',
        },
        modal: {
          discard_title: 'トピックスの作成をキャンセル',
          discard_subtitle: '入力した内容は保存されません。',
          publish_title: 'トピックスを投稿',
          publish_subtitle: 'このトピックスを投稿しますか？',
          publish_message: '新規のトピックスが公開されました。',
          draft_title: '下書きに保存',
          draft_subtitle: 'このトピックスを下書きに保存しますか？',
          draft_message: 'トピックスが下書きに保存されました。',
          delete_title: 'トピックスの削除',
          delete_subtitle: 'このトピックスを削除しますか？',
          delete_message: '選択したトピックスは削除されました。',
        },
      },
      user_guides: {
        title: 'ユーザーガイド一覧',
        admin_no_user_guides_created: 'アップロードされたユーザーガイドはありません。',
        admin_add_user_guides: '「アップロード」からファイルをアップロードできます。',
        not_available: 'まだユーザーガイドはありません。',
        published_at: '投稿日',
        labels: {
          title: 'ファイル名',
          published_at: '投稿日',
        },
        errors: {
          max_file_size: 'ファイルは10MB以下でアップロードしてください。',
          invalid_format: 'ファイルはPDF、XLSX、DOCX、PPTXでアップロードしてください。',
          duplicated_file: 'ファイルが重複しています。',
        },
        modal: {
          delete_title: 'ユーザーガイドの削除',
          delete_subtitle: 'このユーザーガイドを削除しますか？',
          delete_message: '選択したユーザーガイドは削除されました。',
          publish_message: '新規のユーザーガイドが公開されました。',
        },
      },
      interviews: {
        admin_add_interviews: '「+新規作成」から作成できます。',
        click_to_list: '上記をクリックすると、注目のインタビュー記事一覧に移動します。',
        no_interviews_created: '作成されたインタビュー記事はありません。',
        not_available: 'インタビュー記事はまだありません。',
        title: 'インタビュー記事一覧',
        labels: {
          add_interview: '新規作成',
          created_at: '追加日',
          publish_date: '投稿日',
          status: 'ステータス',
          title: 'タイトル',
        },
        modal: {
          publish_title: 'インタビュー記事の掲載',
          publish_subtitle: 'このインタビュー記事を投稿しますか？',
          publish_message: '新規のインタビュー記事が公開されました。',
          draft_title: '下書きに保存',
          draft_subtitle: 'このインタビュー記事を下書きに保存しますか？',
          draft_message: 'インタビュー記事が下書きに保存されました。',
          delete_title: 'インタビュー記事の削除',
          delete_subtitle: 'インタビュー記事を削除しますか？',
          delete_message: '選択したインタビュー記事は削除されました。',
          discard_title: 'ページ移動の確認',
          discard_changes_subtitle: '変更内容は廃棄されます。',
          discard_save_changes_subtitle: '変更を保存しなくてもよろしいですか？',
        },
        placeholders: {
          title: 'インタビュー記事タイトルを入力してください。',
        },
      },
      categories: {
        add_category: 'カテゴリーを追加',
        add_sub_category: 'サブカテゴリーを追加',
        categories_list: 'カテゴリーの追加・編集',
        drag_and_drop_notice: 'のドラッグ&ドロップで順序を変更できます。',
        modal: {
          discard_title: 'ページ移動の確認',
          discard_changes_subtitle: '変更を保存しなくてもよろしいですか？',
          discard_changes_title: '変更内容は廃棄されます。',
          publish_title: 'カテゴリーを保存しました',
          publish_sub_title: '変更を保存しますか？',
          success_message: 'カテゴリーを更新しました。',
        },
        no_categories_available: 'カテゴリはまだありません。',
        placeholder: {
          main_category: 'メインカテゴリー',
          sub_category: 'サブカテゴリー',
        },
        remove: '削除',
        save: '保存',
        title: 'カテゴリー管理',
        update_categories: 'カテゴリーの追加・編集',
      },
    },
    side_menu: {
      top_page: 'トップページへ',
      my_page: 'マイページ',
      document_request_service: '資料請求済みサービス',
      inquiry_history: '問い合わせ履歴',
      logout: 'ログアウト',
      account_information: 'アカウント情報',
      change_email: 'メールアドレス変更',
      change_password: 'パスワード変更',
      service_matching: 'サービスマッチング興味・課題',
      top: 'トップへ',
      service_management: 'サービス管理',
      download_history: '資料ダウンロード履歴',
      announcements: 'お知らせ',
    },
    menu: {
      home: '家',
      about: '約',
      inquiry: 'お問い合わせ',
      faq: 'よくあるご質問',
      dashboard: 'ダッシュボード',
      users: 'ユーザー',
      orders: '注文',
      reports: 'レポート',
      integrations: '統合',
      profile: 'プロフィール',
      login: 'ログイン',
      logout: 'ログアウト',
      terms: '利用規約',
      privacy_policy: 'プライバシーポリシー',
      documentation: 'ドキュメンテーション',
      api_reference: 'APIリファレンス',
      support: 'サポート',
      styleguide: 'スタイルガイド',
      category: 'カテゴリ',
      ranking: 'ランキング',
      user_guide: 'ユーザーガイド',
      topics: 'トピックス',
      news: 'ニュース/記事',
      it_provider_login: '掲載企業ログイン',
      viewer_login: 'ユーザーログイン',
      viewer_registration: '新規アカウント登録',
      document_service: '資料請求済みサービス',
      inquiry_history: '問い合わせ履歴',
      mypage: 'マイページ',
      service_management: 'サービス管理',
      account_management: 'アカウント管理',
      announcement_management: 'お知らせ',
      categories_management: 'カテゴリー管理',
      top_management: 'トップページ管理',
      top_sections: 'トップページ',
      interviews: 'インタビュー記事',
    },
    table: {
      no_data: '表示するデータがありません。',
    },
    footer: {
      company_profile: '会社概要',
      privacy_policy: 'プライバシーポリシー',
      terms: '利用規約',
      subtitle: 'ITサービスマッチングサイト',
      caption:
        '著作者またはその他の権利者の許諾を得ることなく、コンテンツを複製、公衆送信、改変、転載等することは、著作権法上認められている場合を除き、禁止されています。',
      copyright: 'SaaSMart. All Rights Reserved.',
      commercial: '特定商取引法に基づく表記',
    },
    buttons: {
      add: '追加',
      search: '検索',
      save: '保存',
      edit: '編集',
      reset: 'リセット',
      delete: '削除',
    },
    breadcrumbs: {
      saasmart: 'SaaSMart',
      search_result: '検索結果',
      service_comparison: 'サービス比較',
      service_list: '検索結果',
      service_details: 'サービス詳細',
    },
    toast: {
      delete_success: '削除が完了しました。',
      edit_success: 'が編集されました。',
      service_update_success: 'サービス情報が編集されました。',
      service_create_success: '新規サービスが追加されました。',
      announcement_create_published_success: '新規データが保存されました。',
      announcement_create_success: '新規お知らせが登録されました。',
    },
    terms: {
      title_users: 'SaaSMart（サース・マート）利用規約（ユーザー向け）',
      title_company: 'SaaSMart（サース・マート）利用規約（掲載者向け） ',
      caption_users:
        '本利用規約（以下「本規約」といいます）には、株式会社デルタ（以下「当社」といいます）が提供する本サービス（第3条第1号に定義します）の提供条件及び当社とユーザー（第3条第5号に定義します）との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいた上で、本規約に同意いただく必要があります。',
      caption_company:
        '本利用規約（以下「本規約」といいます）には、株式会社デルタ（以下「当社」といいます）が提供する本サービス（第3条第1号に定義します）の提供条件および当社と掲載者（第3条第5号に定義します）との間の権利義務関係が定められています。本サービスの利用に際しては、本規約の全文をお読みいただいたうえで、本規約に同意いただく必要があります。 ',
      policy_title: 'SaaSMart（サース・マート）送信データ取扱いに関するポリシー',
      policy_caption:
        'SaaSMart（サース・マート）利用規約（ユーザー向け）（以下「利用規約」といいます）の第16条第1項で定める送信データの取扱いについて、以下のとおり送信データ取扱いに関するポリシー（以下「本ポリシー」といいます）を定めます。なお、本ポリシーで別段の定めのない限り、用語の定義は利用規約の定めに従うものとします。',
      scroll_to_top: 'トップに戻る',
    },
    admin: {
      account_management: 'アカウント管理',
      id: 'ID',
      account_name: 'アカウント名',
      company_name: '会社名',
      email_address: 'メールアドレス',
      account_type: 'アカウント種別',
      added_date: '作成日',
      status: 'ステータス',
      action: 'アクション',
      add_new: 'アカウント追加',
      verified: '本人確認済み',
      pending: '本人確認待ち',
      add_new_account: 'アカウント追加',
      edit_account: 'アカウント情報編集',
      save: '保存',
      cancel: 'キャンセル',
      last_update: '最終更新日',
      account_status: 'スターテス',
      search: '検索キーワード入力',
      all_roles: 'すべて',
      success_create_user: '新規{{ type }}が登録されました。',
      success_edit_user: '{{ type }}が編集されました。',
      top_sections: 'トップページ',
    },
  },
};

export default ja;
